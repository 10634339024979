import gsap from "gsap";

// Sélectionnez tous les conteneurs
const iconContainers = document.querySelectorAll('[data-parallax]');

iconContainers.forEach((iconContainer) => {
    // Sélectionnez le premier élément enfant de l'iconContainer
    const icon = iconContainer.firstElementChild;

    iconContainer.addEventListener('mouseleave', function () {
        // Réinitialisez l'échelle et la position de l'iconContainer et de l'icon à la normale, si l'icon existe
        gsap.to(iconContainer, { duration: 0.3, scale: 1, x: 0, y: 0 });
        if (icon) gsap.to(icon, { duration: 0.3, scale: 1, x: 0, y: 0 });
    });

    iconContainer.addEventListener('mouseenter', function () {
        // Agrandissez légèrement l'iconContainer à l'entrée de la souris
        gsap.to(iconContainer, { duration: 0.1, transformOrigin: '50% 50%', scale: 1.05 });
    });

    iconContainer.addEventListener('mousemove', function (e) {
        // Appliquez un effet parallaxe seulement si l'icon existe
        if (icon) {
            callParallax(e, iconContainer, 20); // Mouvement plus subtil pour le conteneur
            callParallax(e, icon, 5); // Mouvement plus prononcé pour le premier enfant
        }
    });
});

function callParallax(e, target, movement) {
    // Vérifiez si target existe avant de continuer
    if (!target) return;

    const boundingRect = target.getBoundingClientRect();
    const relX = e.clientX - boundingRect.left;
    const relY = e.clientY - boundingRect.top;
    const movementX = (relX - boundingRect.width / 2) / boundingRect.width * movement;
    const movementY = (relY - boundingRect.height / 2) / boundingRect.height * movement;

    gsap.to(target, 0.3, {
        x: movementX,
        y: movementY,
        ease: "power2.out"
    });
}
